import { cloneElement, createRef, useEffect, useRef, useState } from 'react';

import { Link } from 'react-router-dom';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Container from 'react-bootstrap/Container';

import Contact from '../components/Contact';

// Assets
import MentorImg from '../assets/images/mentor_photo.jpg';
import DeloitteImg from '../assets/images/deloitte_photo.jpg';
import ArtieImg from '../assets/images/gigi_artie.jpg';



export default function Story() {

  	return (

		<div className='gg-page' data-page='story'>

            {/* Hero */}
            {/* <Hero /> */}

            <section className='gg-section gg-my-story text-center' id='my-story'>
            
                <Container>

                    <div className='row'>

                        <div className='col-md-8 offset-md-2'>

                            <div className='gg-my-story__avatar mx-auto'>
                                {/* <img src={Headshot} alt='Gigi Beaudreaux Headshot' /> */}
                            </div>

                            <h2 className='gg-my-story__heading'>My Story</h2>

                            <div className='gg-my-story__desc'>

                                <p>
                                    My name is Gigi Boudreaux and I am a Certified Public Accountant with a BA from
                                    the University of Pennsylvania and an MS in Taxation and MBA in Accounting
                                    from Fordham University. I am a member of AICPA and NYSSCPA. With over 30
                                    years of public accounting under my belt, I am confident in my abilities, but they
                                    mean nothing without knowing the real person behind these credentials.
                                </p>

                                <p>
                                    My story starts in Morristown, NJ where I grew up with my sister on a quiet
                                    street, only boys for neighbors, and lots of steep hills that made bike riding
                                    challenging. When I was quite young (before "working papers"), one of my
                                    neighbors got me a job cleaning lettuce and cutting veggies at a restaurant. I
                                    immediately loved it! Over the course of my high school years, I worked there on
                                    weekends and summers and advanced to Sous-Chef by the time I left for college.
                                    I still use recipes today from that job. But a job meant wages and wages meant
                                    taxes, so I learned at an early age how to prepare my own tax returns. It never
                                    occurred to me to do otherwise.
                                </p>

                                <p>
                                    In my junior year of high school, encouraged by my well-traveled parents, I
                                    entered a contest and won a summer trip to Switzerland as an exchange student.
                                    This eye-opening experience cemented my interest in foreign travel, cultures,
                                    languages, and my decision to major in International Relations at the University of
                                    Pennsylvania.
                                </p>

                                <p>
                                    So <em>how</em> did I end up as an accountant?
                                </p>

                                <p>
                                    When I graduated from Penn, I wasn't entirely sure what I wanted my next steps
                                    to be. On the one hand, I wanted to continue traveling and exploring new
                                    cultures, but on the other hand, I felt a pull from my then-husband-to-be to stay
                                    state-side and follow a more traditional career path. After all, as much as I wanted
                                    to spend my days eating baguettes and sipping on French wine, it wasn't going to
                                    pay the bills.
                                </p>

                                <p>
                                    So, I took a gig at the Vice Provost's office at the University. When tax season
                                    rolled around, I found myself helping all my coworkers with their tax returns.
                                    After all, I had experience. <em>News flash Gigi — not everyone likes doing their taxes!</em>
                                    &nbsp;Whereas they dreaded it, I enjoyed the challenge and, even more so, being able
                                    to help them. This was my "a-ha" moment and I decided to go to graduate school
                                    for accounting. That journey began with evening classes at the Wharton School,
                                    but I soon learned that Fordham University was launching a brand-new graduate
                                    accounting program specifically intended for students who held liberal arts
                                    undergraduate degrees and no business training. The man behind the program,
                                    Dr. Walter O'Connor, was my first (and, by far, my favorite) mentor. He believed
                                    that the accounting industry needed well-rounded professionals who had depth
                                    of knowledge beyond finance and "number crunching." While I learned A LOT
                                    about Accounting, I learned so much more from my mentor who provided me
                                    with the foundation for my approach to working with clients to this day.
                                </p>

                                <img src={MentorImg} alt={'Gigi and her favorite mentor, Dr. Walter O\'Connor'} />

                                <p>
                                    After my first year, I landed an internship with Deloitte, Haskins &amp; Sells, which at
                                    the time was a "Big 8" firm with offices located on the 101st floor of the North
                                    Tower of the World Trade Center. It was certainly the coolest place that I have
                                    worked.
                                </p>

                                <img src={DeloitteImg} alt={'Gigi\' staff photo and page and Deloitte, Haskins, and Sells'} />

                                <p>
                                    At the time of graduation, I received offers from all of the "Big 8" but I decided on
                                    KPMG Peat Marwick. Having enjoyed the work in the Middle Market division at
                                    Deloitte, I opted for a similar group at KPMG. I remained there for 5 years during
                                    which time I got married and had my oldest son. I left KPMG in search of a work
                                    life balance and had my second son 18 months later and my daughter 3 years
                                    after that. For the next 15 years or so, I worked as a part-time CPA and Girl Scout
                                    troop leader, Catechist, sports mom, carpool chauffeur, homeworker helper,
                                    costume maker, clothing mender, and wife to my husband of 33 years now who
                                    was also juggling volunteering and coaching while working as an attorney. I often
                                    reflect back on this time as the most chaotic, exciting, exhausting but the MOST
                                    rewarding time of my life and I would not change a single thing!
                                </p>
                                
                                <p>
                                    Today, my children are all grown up and "out of the nest," as my husband likes to
                                    joke. I'm immensely proud of each of them and the lives and careers they have
                                    created for themselves. Now, it's time to pave a new path for myself - the start of
                                    my own tax and advisory practice and I am very excited for this new opportunity
                                    and chapter of my life.
                                </p>

                                <p>
                                    With a diverse CV, I consider myself a tax generalist. I have the resources and
                                    experience to advise on issues such as tax return preparation, planning and
                                    projection analysis, state and local tax concerns, international tax reporting
                                    requirements, high net worth individual matters, estate and gift tax and related
                                    planning, real estate professional rules, like-kind exchange transactions, and
                                    more.
                                </p>

                                <p>
                                    Above all that, my focus has always been and will continue to be on the quality of
                                    service that I provide to my clients. I'm a people person at heart, and I get my
                                    reward knowing I had a part in the successes of my clients. I am always available
                                    for questions, to work through "what if" scenarios, or just to catch up on life in
                                    general.
                                </p>

                                <p>
                                    So, who is Gigi? I am a wife and mother, a travel enthusiast, a book nerd, and a
                                    chocoholic. I love gardening, days at the beach, and time spent with my family
                                    sitting around the dinner table. But I am also Gigi Boudreaux, CPA, a really good
                                    accountant, and your trusted advisor.
                                </p>

                                <img src={ArtieImg} alt={'Gigi and her partner, Artie'} />

                            </div>

                        </div>

                    </div>

                </Container>
                
            </section>

            {/* Contact */}
            <Contact />

        </div>

  	)

}
