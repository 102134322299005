import { Link } from 'react-router-dom';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Container from 'react-bootstrap/Container';


export default function Services({ sectionRef }) {

	return (

		<section className='gg-section gg-services' id='services' ref={sectionRef}>

            <Container>

                <h2 className='gg-services__heading text-center'>Services</h2> {/* Let's talk taxes */}
                    
                <div className='row gg-services__wrap'>

                    <div className='col-md-6'>

                        <div className='gg-services__service text-center'>

                            <FontAwesomeIcon className='gg-services__icon' icon='fa-solid fa-calculator' />

                            <h3 className='gg-services__name'>Tax Preparation</h3>

                            <p className='gg-services__desc'>
                                We offer efficient and timely preparation of all types of tax returns including, but not limited to, corporate tax filings, individual and fiduciary tax filings. We offer services to clients located all U.S. states and foreign countries.
                            </p>
                        
                        </div>

                    </div>

                    <div className='col-md-6'>

                        <div className='gg-services__service text-center'>

                            <FontAwesomeIcon className='gg-services__icon' icon='fa-solid fa-file-invoice-dollar' />

                            <h3 className='gg-services__name'>Tax Planning</h3>

                            <p className='gg-services__desc'>
                                We work proactively and directly with you to accurately forecast your tax liability and recommend appropriate strategies to maximize your after-tax income.
                            </p>
                        
                        </div>

                    </div>

                    <div className='col-md-6'>

                        <div className='gg-services__service text-center'>

                            <FontAwesomeIcon className='gg-services__icon' icon='fa-solid fa-gift' />

                            <h3 className='gg-services__name'>Estate &amp; Gift Tax Planning</h3>

                            <p className='gg-services__desc'>
                                We work directly with the executor/executrix, estate attorneys and other advisors and guide you through the federal and state estate or gift tax return filing requirements.
                            </p>
                        
                        </div>

                    </div>

                    <div className='col-md-6'>

                        <div className='gg-services__service text-center'>

                            <FontAwesomeIcon className='gg-services__icon' icon='fa-solid fa-comment-dollar' />

                            <h3 className='gg-services__name'>Consulting</h3>

                            <p className='gg-services__desc'>
                                We offer a variety of advisory services including, but not limited to, budgeting and forecasting, business formation and choice of entity, college planning, sale of business, real estate sales planning.
                            </p>
                        
                        </div>

                    </div>


                </div>

            </Container>

        </section>

	)
}
