import { useState } from 'react';

import { Link } from 'react-router-dom';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Container from 'react-bootstrap/Container';

import { send } from 'emailjs-com';


export default function Contact({ sectionRef }) {

    const [formData, setFormData] = useState(
        {
            to_name: 'Gigi',
            name: '',
            email: '',
            phone: '',
            msg: ''
        }
    );

    const [sending, setSending] = useState(false);
    const [completed, setCompleted] = useState(false);

    const handleFormSubmit = (e) => {
        e.preventDefault();
        setSending(true);

        send(
            'service_i5dhgx5', // Service ID
            'template_ne7szoq', // Templace ID
            formData, // Form Fields
            'geWrNeQP4c8zj3IUo' // Account Public Key
        )

        .then((response) => {
            console.log('SUCCESS!', response.status, response.text);
            setCompleted(true);
            setSending(false);
        })

        .catch((err) => {
            console.log('FAILED...', err);
            setCompleted(true);
            setSending(false);
        });
    };

    const handleReset = () => {
        setFormData({
            to_name: 'Gigi',
            name: '',
            email: '',
            phone: '',
            msg: ''
        });
        setCompleted(false);
    };

	return (

		<section className='gg-section gg-contact' id='contact' ref={sectionRef}>

            <Container>

                <h2 className='gg-contact__heading text-center'>
                    {completed ? 'Thank you for reaching out!' : 'Want to get in touch?'}
                </h2>
                        
                <form className='gg-contact__form' onSubmit={handleFormSubmit}>

                    <div className='gg-contact__form-row row'>

                        <div className='col-md-6'>
                            <label>Name</label>
                            <input
                                className='form-control'
                                onChange={(e) => setFormData({...formData, name: e.target.value})} 
                                placeholder='Name'
                                type='text'
                                value={formData.name}
                            />
                        </div>

                        <div className='col-md-6'>
                            <label>Phone</label>
                            <input
                                className='form-control'
                                onChange={(e) => setFormData({...formData, phone: e.target.value})} 
                                placeholder='(123) 456-7890'
                                type='tel'
                                value={formData.phone}
                            />
                        </div>

                    </div>

                    <div className='gg-contact__form-row row'>
                        
                        <div className='col'>
                            <label>Email</label>
                            <input
                                className='form-control'
                                onChange={(e) => setFormData({...formData, email: e.target.value})} 
                                placeholder='Email'
                                type='email'
                                value={formData.email}
                            />
                        </div>

                    </div>

                    <div className='gg-contact__form-row row'>
                        
                        <div className='col'>
                            <label>Message</label>
                            <textarea
                                className='form-control'
                                rows='5'
                                placeholder='Let me know how I can help you...'
                                onChange={(e) => setFormData({...formData, msg: e.target.value})}
                                value={formData.msg}
                            >
                            </textarea>
                        </div>

                    </div>

                    
                    <div className='gg-contact__btn-wrap text-center'>
                        <button className='gg-btn' type='submit'>
                            Send Message
                        </button>
                    </div>

                    {sending && (
                        <div className='gg-contact__spinner-wrap'>
                            <div className='gg-contact__spinner'></div>
                        </div>
                    )}

                    {completed && (
                        <div className='gg-contact__completed-wrap'>
                            <button className='gg-btn' onClick={handleReset}>
                                Send Another Message
                            </button>
                        </div>
                    )}

                </form>

                

            </Container>

        </section>

	)
}