import { Link } from 'react-router-dom';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Container from 'react-bootstrap/Container';


export default function HelpfulLinks({ sectionRef }) {

	return (

		<section className='gg-section gg-links' id='links' ref={sectionRef}>

            <Container>

                <div className='row'>

                    <div className='col-md-8 offset-md-2 text-center'>

                        <h2 className='gg-links__heading'>Helpful Links</h2>

                        <p className='gg-links__desc'>
                            For the Internal Revenue Service or tax services in your home state.
                        </p>

                    </div>

                </div>

                <div className='gg-links__body row'>

                    {/* Can replace offset class with 'offset-md-2' and a second 'col-md-4' div for a two column layout */}
                    <div className='col-md-4 offset-md-4 text-center'>

                        {/* Commenting Category title until we have more than one category */}
                        {/* <h3 className='text-center'>Category 1</h3> */}

                        {/* To achieve look from online, add data-replace attribute to Link element with text content inside span as the value */}
                        <a className='gg-text-link' href='https://www.irs.gov/payments/your-online-account' target='_blank'>
                            <span>IRS Online Services</span>
                        </a>

                        <a className='gg-text-link' href='https://www.tax.ny.gov/online/' target='_blank'>
                            <span>New York State Online Services</span>
                        </a>

                        <a className='gg-text-link' href='https://www1.state.nj.us/TYTR_RevTaxPortal/jsp/IndTaxLoginJsp.jsp' target='_blank'>
                            <span>New Jersey Online Services</span>
                        </a>

                        <a className='gg-text-link' href='https://drs.ct.gov/eservices/_/#1' target='_blank'>
                            <span>Connecticut Online Services</span>
                        </a>

                    </div>
                    
                </div>

            </Container>

        </section>

	)
}
