import { Link } from 'react-router-dom';

import Container from 'react-bootstrap/Container';

// Assets
import Headshot from '../assets/images/headshot.jpg';


export default function Bio({ sectionRef }) {

	return (

		<section className='gg-section gg-bio text-center' id='bio' ref={sectionRef}>
                
            <Container>

                <div className='row'>

                    <div className='col-md-8 offset-md-2'>

                        <div className='gg-bio__avatar mx-auto'>
                            {/* <img src={Headshot} alt='Gigi Beaudreaux Headshot' /> */}
                        </div>

                        <h2 className='gg-bio__heading'>About Gigi</h2>

                        <div className='gg-bio__desc'>

                            <p>
                                Gigi Boudreaux CPA LLC is a boutique CPA firm located in Massapequa, Long Island, New
                                York. We specialize in tax and consulting work for small businesses, high net worth individuals,
                                trust and estates and tax preparation services as well as a variety of consulting services. We
                                work directly with you to provide strategies and solutions that will help you achieve financial
                                success and maximize tax efficiencies.
                            </p>

                            <p>
                                With over 30 years of experience, we pride ourselves on giving each one of our clients
                                personalized and detailed attention. We want to be your most trusted advisor. Please call to find
                                out if Gigi Boudreaux CPA LLC is a good option for you. We look forward to hearing from you.
                            </p>

                        </div>

                    </div>

                </div>

            </Container>
            
        </section>

	)
}
