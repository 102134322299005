import { Link } from 'react-router-dom';


export default function Hero() {

	return (

		<div className='gg-hero'>

            <div className='gg-hero__mask'>

                <div className='container d-flex justify-content-center justify-content-md-start align-items-center h-100'>

                    <div className='gg-hero__text-wrap'>

                        <h1 className='gg-hero__heading'>Gigi Boudreaux</h1>

                        <p className='gg-hero__subhead'>Certified Public Accountant</p>

                        <div className='gg-hero__cta-wrap'>

                            <a className='gg-btn gg-btn--light' href='#services'>
                                Services
                            </a>

                            <a className='gg-btn gg-btn--light' href='#contact'>
                                Get in Touch
                            </a>

                        </div>

                    </div>

                </div>

            </div>

        </div>

	)
}
