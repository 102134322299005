import { Routes, Route } from 'react-router-dom';

import StandardLayout from './layouts/StandardLayout';

import Home from './pages/Home';
import Story from './pages/Story';


/* import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css"; */

function App() {

  return (

  	<Routes>

		<Route path='/' element={<StandardLayout />}>

			<Route path='' element={<Home />} />

			<Route path='/story' element={<Story />} />

			{/* 
				<Route path="*">
					<NotFound/>
				</Route> 
			*/}

		</Route>


	</Routes>

  );
}

export default App;
