// import the library
import { library } from '@fortawesome/fontawesome-svg-core';

// import your icons
/* import { faLinkedin, faInstagram, faFacebook, faYoutube } from '@fortawesome/free-brands-svg-icons'; */
import {
    faBars,
    faCalculator,
    faFileInvoiceDollar,
    faCommentDollar,
    faGift,
    faPhone,
    faEnvelope
} from '@fortawesome/free-solid-svg-icons';

library.add(
    faBars,
    faCalculator,
    faFileInvoiceDollar,
    faCommentDollar,
    faGift,
    faPhone,
    faEnvelope
);