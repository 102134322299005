import { Link } from 'react-router-dom';

import { useRef, useEffect, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Offcanvas from 'react-bootstrap/Offcanvas';

// Assets
import brandLogo from '../assets/images/logo_400x1000.png';


export default function Header() {

	// Header Reference
    const headerRef = useRef(null);


	return (

		/* <header className='fm-header sticky-top' ref={headerRef}>

            <nav className='fm-header__inner d-flex justify-content-between align-items-center'>

                <div className='fm-header__brand'>
                    <a className='fm-header__brand-link' href='/'>
                        <img src={BrandImg} alt='Flux Marine' />
                    </a>
                </div>

            </nav>

		</header> */

        /* Add sticky-top class here if desired */
        <header className='gg-header' ref={headerRef}>

            <Navbar className='gg-navbar' expand='lg' /* sticky="top" */>

                <Container>

                    <Navbar.Brand className='gg-navbar__brand' href='/'>
                        <img className='img-fluid' src={brandLogo} alt='Gigi Boudreaux, C.P.A.' />
                    </Navbar.Brand>

                    <Navbar.Toggle aria-controls='offcanvasNavbar' className='gg-header__menu-btn'>
                        <FontAwesomeIcon icon='fa-solid fa-bars' />
                    </Navbar.Toggle>
                    
                    <Navbar.Offcanvas
                        id='offcanvasNavbar'
                        aria-labelledby='offcanvasNavbarLabel'
                        placement='end'
                    >

                        <Offcanvas.Header closeButton>
                            <Offcanvas.Title id='offcanvasNavbarLabel'>
                                Menu
                            </Offcanvas.Title>
                        </Offcanvas.Header>

                        <Offcanvas.Body>

                            <Nav className="gg-navbar__nav me-auto">

                                {/* <Nav.Link href="/">Home</Nav.Link> */}

                                <Nav.Link className='gg-text-link gg-navbar__link' href="#bio">About</Nav.Link>

                                <Nav.Link className='gg-text-link gg-navbar__link' href="#services">Services</Nav.Link>

                                <Nav.Link className='gg-text-link gg-navbar__link' href="#links">Links</Nav.Link>

                                <Nav.Link className='gg-text-link gg-navbar__link' href="#contact">Contact</Nav.Link>

                                <Nav.Link className='gg-text-link gg-navbar__link' href="/story">My Story</Nav.Link>

                            </Nav>

                            <Nav className='ml-auto'>
                                <a className='gg-btn gg-navbar__portal-btn' href='https://www.clientaxcess.com' target='_blank'>
                                    Client Portal
                                </a>
                            </Nav>

                        </Offcanvas.Body>

                    {/* </Navbar.Collapse> */}
                    </Navbar.Offcanvas>

                </Container>

            </Navbar>

        </header>

    

	)
}
