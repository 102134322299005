import { cloneElement, createRef, useEffect, useRef, useState } from 'react';

import { Link, useSearchParams } from 'react-router-dom';

import HashLinkObserver from "react-hash-link"; 

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Container from 'react-bootstrap/Container';

import Hero from '../components/Hero';
import Bio from '../components/Bio';
import Services from '../components/Services';
import HelpfulLinks from '../components/HelpfulLinks';
import Contact from '../components/Contact';


export default function Home(props) {

      const bioRef = useRef();
      const servicesRef = useRef();
      const linksRef = useRef();
      const contactRef = useRef();

  	return (

		<div className='gg-page' data-page='home'>

                  <HashLinkObserver />

                  {/* Hero */}
                  <Hero />

                  {/* Bio */}
                  <Bio sectionRef={bioRef} />

                  {/* Services */}
                  <Services sectionRef={servicesRef} />

                  {/* Helpful Links */}
                  <HelpfulLinks sectionRef={linksRef} />

                  {/* Contact */}
                  <Contact sectionRef={contactRef} />

            </div>

  	)

}
