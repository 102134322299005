
import { useRef, useEffect, useState } from 'react';

import { Link } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Container from 'react-bootstrap/Container';

// SVG Assets
// import BrandImg from '../assets/images/brand/flux_marine_horiz_black.svg';


export default function Footer() {

	// Footer Reference
    const footerRef = useRef(null);

	return (

		<footer className='gg-footer' ref={footerRef}>

			<Container className='d-flex flex-column align-items-md-center flex-lg-row justify-content-lg-between'>

				<div className='gg-footer__contact d-flex flex-column flex-md-row'>

					{/* Need to update with proper telephone number in both href and span */}
					<a className='gg-text-link' href='tel:+15163138908'>
						<FontAwesomeIcon icon='fa-solid fa-phone' />
						<span>(516) 313-8908</span>
					</a>

					{/* Need to update with proper email address in both href and span */}
					<a className='gg-text-link' href='mailto: gigiboudreauxcpa@gmail.com'>
						<FontAwesomeIcon icon='fa-solid fa-envelope' />
						<span>gigiboudreauxcpa@gmail.com</span>
					</a>

				</div>

				<div className='gg-footer__copyright text-center text-lg-start'>
					&copy; 2022 Gigi Boudreaux CPA LLC.
				</div>

			</Container>

		</footer>

	)
}
